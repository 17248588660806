import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../helper";
import ProductAddRemoveController from "../ProductAddRemoveController/ProductAddRemoveController";

import styles from "./CartItem.module.css";
const CartItem = ({ product, moq }) => {
  return (
    <div className={styles.container}>
      <div style={{ display: "flex" }}>
        <Link className={styles.link} to={`/products/${product._id}`}>
          <img
            className={styles.img}
            loading="lazy"
            src={`${IMAGE_BASE_URL}/${product.img}`}
            alt={product.title}
          />
        </Link>
        <div className={styles.content}>
          <Link className={styles.link} to={`/products/${product._id}`}>
            <div className={styles.title}>{product.title}</div>
          </Link>
          <div className={styles.price}>{"Rs. " + product.price}</div>
        </div>
      </div>

      <ProductAddRemoveController
        product={product}
        moq={moq || 0}
        enableDelete
        disableAutoremove
      />
    </div>
  );
};

export default CartItem;
