import { LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../components/ProductCard";
import { fetchProducts } from "../../redux/productsSlice";

const HomeScreen = () => {
  const dispatch = useDispatch();

  const {
    items: products,
    loading,
    error,
  } = useSelector((state) => state.products);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (products.length === 0 && !error) {
      dispatch(fetchProducts());
    }
  }, [dispatch, products, error]);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        // gap: "1.5rem",
      }}
    >
      {products.map((product) => (
        <ProductCard key={product._id} product={product} admin={user.admin} />
      ))}
    </div>
  );
};

export default HomeScreen;
