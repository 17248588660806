import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  loading: false,
  error: null,
  items: [],
};

export const fetchProducts = createAsyncThunk(
  "products/get",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get("./api/products");
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Error while fetching products"
      );
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateProductInRedux: (state, { payload }) => {
      const index = state.items.findIndex(
        (item) => item._id.toString() === payload._id.toString()
      );
      if (index >= 0) {
        state.items[index] = payload;
      }
    },
  },
  extraReducers: {
    [fetchProducts.pending]: (state) => {
      state.loading = true;
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.items = action.payload || [];
      if (state.error) {
        state.error = null;
      }
      state.loading = false;
    },
    [fetchProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { updateProductInRedux } = productsSlice.actions;
export default productsSlice.reducer;
