import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuth } from "firebase/auth";

const initialState = {
  loading: false,
  error: null,
  items: [],
};

export const getAllCoupons = createAsyncThunk(
  "coupon/fetchAll",
  async (_, thunkAPI) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const { data } = await axios.get("./api/coupons", {
        headers: {
          authorization: `Bearer ${token}`,
          uid: auth.currentUser.uid,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Error while fetching coupons"
      );
    }
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllCoupons.pending]: (state) => {
      state.loading = true;
    },
    [getAllCoupons.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.items = payload;
      if (state.error) {
        state.error = null;
      }
    },
    [getAllCoupons.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default couponSlice.reducer;
