import React from "react";
import styles from "./ProductCard.module.css";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../helper";
const ProductCard = ({ product, admin }) => {
  return (
    <Link
      className={styles.link}
      to={`${admin ? "/admin" : ""}/products/${product._id}`}
    >
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.price}>{"Rs. " + product.price}</div>
          <img
            loading="lazy"
            className={styles.img}
            src={`${IMAGE_BASE_URL}/${product.image}`}
            alt={product.title}
          />
        </div>
        <div className={styles.bottom}>
          <div className={styles.title}>{product.title}</div>
        </div>
      </div>
    </Link>
  );
};
export default ProductCard;
