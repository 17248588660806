import React, { useState } from "react";
import styles from "./Navbar.module.css";
import {
  Avatar,
  Badge,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { Box } from "@mui/system";
import { logout } from "../../redux/authSlice";
import GuestSearchOrderDialog from "../GuestSearchOrderDialog/GuestSearchOrderDialog";
const Navbar = () => {
  const location = useLocation();
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.cart);

  const { authenticated, user } = useSelector((state) => state.auth);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [open, setOpen] = useState(false);

  return (
    <nav className={styles.nav}>
      {open && (
        <GuestSearchOrderDialog
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
      <Container maxWidth="xl" className={styles.navContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {location.pathname !== "/" && (
            <IconButton
              onClick={() => {
                window.history.back();
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <Link className={styles.brand} to="/">
            SurabhiArtGallery
          </Link>
        </div>
        <div className={styles.menu_container}>
          <Badge
            className={styles.icon}
            onClick={() => {
              navigate("/cart");
            }}
            badgeContent={
              items.reduce((prev, curr) => {
                return prev + curr.quantity;
              }, 0) || "0"
            }
            color="primary"
          >
            <ShoppingCartIcon />
          </Badge>

          <>
            <Box sx={{ flexGrow: 1, textAlign: "right" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 4 }}>
                  {auth.currentUser ? (
                    <Avatar alt="user" src={auth.currentUser.photoURL} />
                  ) : authenticated ? (
                    <Avatar alt="user">G</Avatar>
                  ) : (
                    <Avatar alt="user">
                      <AccountCircleIcon
                        style={{ margin: 0, color: "black" }}
                      />
                    </Avatar>
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {authenticated && (
                  <MenuItem style={{ fontWeight: "bold", cursor: "default" }}>
                    Hello, {user.name}
                  </MenuItem>
                )}

                {authenticated && user.email !== "guest@user.com" && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate("/orders");
                    }}
                  >
                    Orders
                  </MenuItem>
                )}

                {authenticated && user.email === "guest@user.com" && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      setOpen(true);
                    }}
                  >
                    Order Details
                  </MenuItem>
                )}

                {authenticated && user.admin && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate("/admin/products");
                    }}
                  >
                    Add Product
                  </MenuItem>
                )}

                {authenticated && user.admin && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate("/admin/coupons");
                    }}
                  >
                    Coupons
                  </MenuItem>
                )}

                {authenticated && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      dispatch(logout());
                      navigate("/");
                    }}
                  >
                    Logout
                  </MenuItem>
                )}

                {!authenticated && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      setOpen(true);
                    }}
                  >
                    Order Details
                  </MenuItem>
                )}

                {!authenticated && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate("/login");
                    }}
                  >
                    Login
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </>
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;
