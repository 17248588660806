import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuth } from "firebase/auth";
const initialState = {
  loading: false,
  error: null,
  items: [],
  fetched: false,
};

export const fetchOrders = createAsyncThunk(
  "orders/get",
  async (_, thunkAPI) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const { data } = await axios.get("./api/orders", {
        headers: {
          authorization: `Bearer ${token}`,
          uid: auth.currentUser.uid,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || "Error while fetching orders"
      );
    }
  }
);

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrder: (state, action) => {
      const index = state.items.findIndex((i) => i._id === action.payload._id);
      if (index >= 0) {
        state.items[index] = action.payload;
      } else {
        state.items.push(action.payload);
      }
    },
    addStatus: (state, action) => {
      const index = state.items.findIndex((i) => i._id === action.payload._id);
      if (index >= 0) {
        state.items[index].updates.push(action.payload.data);
      }
    },
    markDeliver: (state, action) => {
      const index = state.items.findIndex((i) => i._id === action.payload._id);
      if (index >= 0) {
        state.items[index].isDelivered = action.payload.isDelivered;
        state.items[index].deliveredAt = action.payload.deliveredAt;
        state.items[index].updates.push({
          msg: "Delivered",
          date: action.payload.deliveredAt,
        });
      }
    },
    refundOrder: (state, action) => {
      const { _id, ...other } = action.payload;
      const index = state.items.findIndex((i) => i._id === _id);
      if (index >= 0) {
        state.items[index].refund = other;
      }
    },
  },
  extraReducers: {
    [fetchOrders.pending]: (state) => {
      state.loading = true;
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.items = action.payload || [];
      state.fetched = true;
      if (state.error) {
        state.error = null;
      }
      state.loading = false;
    },
    [fetchOrders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { addOrder, addStatus, markDeliver, refundOrder } =
  orderSlice.actions;
export default orderSlice.reducer;
