import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { updateProductInRedux } from "./productsSlice";

const readCartFromLocalStorage = () => {
  let data = localStorage.getItem("cartItems");
  if (data) {
    data = JSON.parse(data);
    if (Array.isArray(data) && data.every((item) => item.quantity > 0)) {
      return data;
    } else {
      localStorage.removeItem("cartItems");
    }
  }
  return [];
};

const initialState = {
  loading: false,
  error: null,
  items: readCartFromLocalStorage(),
  totalPrice: 0,
};

export const addToCart = createAsyncThunk(
  "cart/add",
  async (item, thunkAPI) => {
    try {
      let dataFromLocalStorge = localStorage.getItem("cartItems");
      let items = [];

      if (!dataFromLocalStorge) {
        items.push(item);
      } else {
        dataFromLocalStorge = JSON.parse(dataFromLocalStorge);
        if (Array.isArray(dataFromLocalStorge)) {
          items = [...dataFromLocalStorge];
          const itemIndex = items.findIndex((i) => i._id === item._id);
          if (itemIndex >= 0) {
            items[itemIndex].quantity += 1;
          } else {
            items.push(item);
          }
        } else {
          items.push(item);
        }
      }
      localStorage.setItem("cartItems", JSON.stringify(items));
      return items;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error while adding items to cart.");
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/remove",
  async (id, thunkAPI) => {
    try {
      let dataFromLocalStorge = localStorage.getItem("cartItems");
      let items = [];

      if (dataFromLocalStorge) {
        dataFromLocalStorge = JSON.parse(dataFromLocalStorge);

        if (Array.isArray(dataFromLocalStorge)) {
          items = [...dataFromLocalStorge];
          const itemIndex = items.findIndex((i) => i._id === id);
          if (itemIndex >= 0) {
            items[itemIndex].quantity -= 1;
            if (items[itemIndex].quantity === 0) {
              items.splice(itemIndex, 1);
            }
          }
        } else {
          items = [];
        }
      }
      localStorage.setItem("cartItems", JSON.stringify(items));
      return items;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error while removing items to cart.");
    }
  }
);

export const deleteFromCart = createAsyncThunk(
  "cart/delete",
  async (id, thunkAPI) => {
    try {
      let dataFromLocalStorge = localStorage.getItem("cartItems");
      let items = [];

      if (dataFromLocalStorge) {
        dataFromLocalStorge = JSON.parse(dataFromLocalStorge);

        if (Array.isArray(dataFromLocalStorge)) {
          items = [...dataFromLocalStorge];
          const itemIndex = items.findIndex((i) => i._id === id);
          if (itemIndex >= 0) {
            items.splice(itemIndex, 1);
          }
        } else {
          items = [];
        }
      }
      localStorage.setItem("cartItems", JSON.stringify(items));
      return items;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error while removing items to cart.");
    }
  }
);

export const forceUpdateCartPrice = createAsyncThunk(
  "cart/forceUpdate",
  async (_, thunkAPI) => {
    try {
      const { items } = thunkAPI.getState().cart;
      const dataItems = [...items];

      for (let i = 0; i < items.length; i++) {
        const { data } = await axios.get(`./api/products/${items[i]._id}`);
        thunkAPI.dispatch(updateProductInRedux(data));
        dataItems[i] = { ...items[i], price: data.price };
      }

      localStorage.setItem("cartItems", JSON.stringify(dataItems));
      return dataItems;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Error while force updating the values.");
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCart: (state) => {
      window.localStorage.removeItem("cartItems");
      state.items = [];
    },
    setCartForce: (state, { payload }) => {
      state.items = payload;
    },
  },
  extraReducers: {
    [addToCart.pending]: (state) => {
      state.loading = true;
    },
    [addToCart.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.loading = false;
      if (state.error) {
        state.error = null;
      }
    },
    [addToCart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [removeFromCart.pending]: (state) => {
      state.loading = true;
    },
    [removeFromCart.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.loading = false;
      if (state.error) {
        state.error = null;
      }
    },
    [removeFromCart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteFromCart.pending]: (state) => {
      state.loading = true;
    },
    [deleteFromCart.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.loading = false;
      if (state.error) {
        state.error = null;
      }
    },
    [deleteFromCart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [forceUpdateCartPrice.pending]: (state) => {
      state.loading = true;
    },
    [forceUpdateCartPrice.fulfilled]: (state, { payload }) => {
      if (state.error) {
        state.error = null;
      }
      state.items = payload;
      state.loading = false;
    },
    [forceUpdateCartPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { clearCart, setCartForce } = cartSlice.actions;
export default cartSlice.reducer;
