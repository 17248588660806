import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const readShippingAddressFromLocalStorage = () => {
  let data = localStorage.getItem("shippingAddress");
  if (data) {
    data = JSON.parse(data);
    if (Object.keys(data).length === 7) {
      return data;
    } else {
      localStorage.removeItem("shippingAddress");
    }
  }
  return {
    name: "",
    address: "",
    city: "",
    state: "",
    postal: "",
    country: "",
    phone: "",
  };
};

const initialState = {
  loading: false,
  error: null,
  fullAddress: readShippingAddressFromLocalStorage(),
};

export const saveShippingAddress = createAsyncThunk(
  "shippingAddress/save",
  async (data, thunkAPI) => {
    try {
      localStorage.setItem("shippingAddress", JSON.stringify(data));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error while saving address");
    }
  }
);

const shippingSlice = createSlice({
  name: "shippingAddress",
  initialState,
  reducers: {},
  extraReducers: {
    [saveShippingAddress.pending]: (state) => {
      state.loading = true;
    },
    [saveShippingAddress.fulfilled]: (state, action) => {
      state.fullAddress = action.payload;
      state.loading = false;
      if (state.error) {
        state.error = null;
      }
    },
    [saveShippingAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default shippingSlice.reducer;
