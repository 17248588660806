import { TextField } from "@mui/material";
import React, { useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

const GuestSearchOrderDialog = ({ open, handleClose }) => {
  const orderIdRef = useRef();
  const navigate = useNavigate();

  return (
    <ConfirmDialog
      loading={false}
      open={open}
      handleClose={handleClose}
      title="Search Guest's Order"
      proceed="Search"
      cancel="Cancel"
      content={
        <>
          <TextField
            variant="outlined"
            fullWidth
            margin="dense"
            size="small"
            label="Order ID"
            focused
            inputRef={orderIdRef}
          />

          <div>Search here if you have ordered via guest login.</div>
        </>
      }
      actionHandler={() => {
        if (!orderIdRef.current.value || !orderIdRef.current.value.trim()) {
          toast.error("Please enter valid order id.");
          return;
        }
        handleClose();
        navigate(`/orders/${orderIdRef.current.value.trim()}`);
      }}
    />
  );
};

export default GuestSearchOrderDialog;
