import { Button, IconButton } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  addToCart,
  removeFromCart,
  deleteFromCart,
} from "../../redux/cartSlice";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./ProductAddRemoveController.module.css";

const ProductAddRemoveController = ({
  product,
  moq,
  enableDelete,
  disableAutoremove,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            dispatch(removeFromCart(product._id));
          }}
          disabled={disableAutoremove ? product.quantity === 1 : false}
          variant="outlined"
        >
          <ChevronLeftIcon fontSize="large" />
        </Button>
        <p className={styles.qty}>{product.quantity}</p>
        <Button
          disabled={product.quantity >= moq}
          onClick={() => {
            dispatch(
              addToCart({
                _id: product._id,
                img: product.img,
                title: product.title,
                price: product.price,
                sc: product.sc,
                quantity: 1,
              })
            );
          }}
          variant="outlined"
        >
          <ChevronRightIcon fontSize="large" />
        </Button>
      </div>
      {enableDelete && (
        <IconButton
          onClick={() => {
            dispatch(deleteFromCart(product._id));
          }}
          size="large"
          color="error"
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      )}
    </div>
  );
};

export default ProductAddRemoveController;
