import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ProductScreen.module.css";
import { Button, LinearProgress } from "@mui/material";
import toast from "react-hot-toast";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import ProductAddRemoveController from "../../components/ProductAddRemoveController/ProductAddRemoveController";
import axios from "axios";
import Error404 from "../../components/Error404/Error404";
import { IMAGE_BASE_URL } from "../../helper";
const ProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const products = useSelector((state) => state.products.items);
  const productCache = products.find((item) => item._id === id);

  const [loading, setLoading] = useState(productCache ? false : true);
  const [product, setProduct] = useState(productCache || {});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data } = await axios.get(`./api/products/${id}`);
        setProduct(data);
        setLoading(false);
      } catch (error) {
        setError(error?.response?.data?.message || "Product not found!");
        setLoading(false);
      }
    };
    if (!productCache) {
      fetchProduct();
    }
    window.scrollTo({ top: 0 });
  }, [id, productCache]);

  const { items: cartItems } = useSelector((state) => state.cart);
  const cartItem = cartItems.find((item) => item._id === product._id) || {};

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Error404 error={error} />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img
          loading="lazy"
          src={`${IMAGE_BASE_URL}/${product.image}`}
          alt={product.title}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.title}>{product.title}</div>
        <div>
          <div className={styles.price}>{"Rs. " + product.price}</div>
          <div className={`${styles.sc} ${!product.sc ? styles.free : ""}`}>
            {product.sc ? "Shipping Charge Rs. " + product.sc : "Free Delivery"}
          </div>
        </div>
        <div className={styles.description}>
          <div>Description</div>
          <ul>
            {(product.descriptions || []).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        {product.stock > 0 && product.moq > 0 ? (
          <div className={styles.btnContainer}>
            <Button
              className={styles.btnAddCart}
              variant="contained"
              color="error"
              onClick={() => {
                if (!cartItem.quantity) {
                  dispatch(
                    addToCart({
                      _id: product._id,
                      img: product.img,
                      title: product.title,
                      price: product.price,
                      sc: product.sc,
                      quantity: 1,
                    })
                  );
                }
                navigate("/cart");
              }}
            >
              Order Now
            </Button>
            {!cartItem.quantity && (
              <Button
                startIcon={<AddShoppingCartIcon />}
                size="large"
                variant="contained"
                className={styles.btnAddCart}
                onClick={() => {
                  dispatch(
                    addToCart({
                      _id: product._id,
                      img: product.img,
                      title: product.title,
                      price: product.price,
                      sc: product.sc,
                      quantity: 1,
                    })
                  );
                  toast.success("Added to Cart!");
                }}
              >
                Add to Cart
              </Button>
            )}
            {cartItem.quantity > 0 && (
              <ProductAddRemoveController
                product={cartItem}
                moq={
                  product
                    ? product.moq < product.stock
                      ? product.moq
                      : product.stock
                    : 0
                }
              />
            )}
          </div>
        ) : (
          <div style={{ color: "red" }}>Currently unavailable.</div>
        )}
        <div style={{ height: "2rem" }}></div>
      </div>
    </div>
  );
};

export default ProductScreen;
