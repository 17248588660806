export const IMAGE_BASE_URL = "https://i.ibb.co";

export const formatDate = (value) => {
  if (!value) {
    return null;
  }
  const date = new Date(value);
  //return date.toDateString();
  return date.toLocaleString("default", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const YYYYMMDD = (value) => {
  if (!value) {
    return null;
  }
  let d = new Date(value),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateTime = (value) => {
  if (!value) {
    return null;
  }
  const date = new Date(value);
  // const dateTime =
  //   date.toDateString() + ", " + date.toLocaleTimeString().substring(0, 5);
  // return dateTime.split(" ").slice(1).join(" ");
  return date.toLocaleString("default", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });
};
