import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import styles from "./CartScreen.module.css";
import CartItem from "../../components/CartItem/CartItem";
import { Button, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
const CartScreen = () => {
  const navigate = useNavigate();
  const { items, loading, error } = useSelector((state) => state.cart);
  const products = useSelector((state) => state.products.items);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  if (loading) {
    return <LinearProgress />;
  }

  if (items.length === 0) {
    return (
      <div className={styles.noContainer}>
        <ProductionQuantityLimitsIcon
          className={styles.cartIcon}
          color="primary"
        />
        <div className={styles.noTitle}>Whoops... Nothing in here!</div>
        <div className={styles.noContent}>
          Explore around to add items in your shopping cart.
        </div>
        <Button
          variant="outlined"
          color="error"
          size="large"
          endIcon={<ChevronRightOutlinedIcon />}
          onClick={() => {
            navigate("/");
          }}
        >
          Explore
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <div>
          <div className="headingTitle">
            SHOPPING CART (
            {items.reduce((prev, curr) => {
              return prev + curr.quantity;
            }, 0)}
            )
          </div>
          <div className={styles.totalPrice}>
            {"Rs. " +
              items.reduce((prev, curr) => {
                return prev + curr.quantity * curr.price;
              }, 0)}
          </div>
        </div>
        <Button
          className={styles.btnCheckout}
          onClick={() => {
            navigate(`/login?redirect=checkout`, {
              state: { from: "cart" },
            });
          }}
          variant="contained"
          size="large"
        >
          Checkout
        </Button>
      </div>

      <div className={styles.container}>
        {items.map((item) => {
          const product = products.find((i) => i._id === item._id);
          return (
            <CartItem
              key={item._id}
              product={item}
              moq={
                product
                  ? product.moq < product.stock
                    ? product.moq
                    : product.stock
                  : 0
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default CartScreen;
