import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import styles from "./Footer.module.css";
import { Container } from "@mui/material";

const Footer = () => {
  return (
    <footer className={styles.container}>
      <Container maxWidth="xl">
        <div className={styles.bottom}>
          <div>
            copyright &copy; surabhiartgallery {new Date().getFullYear()}
          </div>
          <div className={styles.b_center}>
            <a className={styles.link} href="mailto:surabhirakhiart@gmail.com">
              <EmailIcon fontSize="large" />
            </a>
            <a
              className={styles.link}
              href="https://instagram.com/surabhiartgallery"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon fontSize="large" />
            </a>
            <a
              className={styles.link}
              href="https://facebook.com/surabhiartgallery"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon fontSize="large" />
            </a>
          </div>
          <div className={styles.b_right}>
            <div>Privacy Policy</div>
            <div>Terms of Use</div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
