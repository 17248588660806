import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ConfirmDialog = ({
  open,
  handleClose,
  title,
  content,
  loading,
  actionHandler,
  proceed,
  cancel,
}) => {
  const handleCloseDialog = (event, reason) => {
    event.preventDefault();
    if (reason && reason === "backdropClick") return;
    handleClose();
  };

  if (!open) {
    return null;
  }
  return (
    <Dialog
      keepMounted={false}
      open={open}
      disableEscapeKeyDown
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {content && (
        <DialogContent style={{ paddingTop: 1 }}>{content}</DialogContent>
      )}
      <DialogActions>
        <Button disabled={loading} color="error" onClick={handleCloseDialog}>
          {cancel ? cancel : "No"}
        </Button>
        <Button disabled={loading} color="primary" onClick={actionHandler}>
          {proceed ? proceed : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
