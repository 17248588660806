import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import cartReducer from "./cartSlice";
import orderReducer from "./orderSlice";
import productsReducer from "./productsSlice";
import shippingReducer from "./shippingSlice";
import couponReducer from "./couponSlice";

const store = configureStore({
  devTools: false,
  reducer: {
    auth: authReducer,
    order: orderReducer,
    products: productsReducer,
    cart: cartReducer,
    shipping: shippingReducer,
    coupon: couponReducer,
  },
});

export default store;
