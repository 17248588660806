import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import styles from "./LoginScreen.module.css";
import { toast } from "react-hot-toast";
import { provider } from "../../helper/Firebase";
import { login } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signInWithPopup } from "firebase/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);

  const { authenticated, user: stateUser } = useSelector((state) => state.auth);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (
        (auth.currentUser && user) ||
        (authenticated &&
          stateUser.name === "Guest" &&
          stateUser.email === "guest@user.com")
      ) {
        navigate(`/${params.get("redirect") || ""}`, {
          replace: true,
          state: location.state,
        });
      } else {
        setLoading(false);
      }
    });
  }, [auth, navigate, params, location, authenticated, stateUser]);

  const loginHandler = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, provider);
      dispatch(login());
    } catch (error) {
      console.log(error);
      toast.error(error.message || "Something went wrong, Please login again.");
      setLoading(false);
    }
  };

  const loginAsGuest = () => {
    dispatch(login("guest"));
  };

  return (
    <div className={styles.newContainer}>
      <div className={styles.container}>
        <Button
          onClick={loginHandler}
          variant="contained"
          size="large"
          startIcon={<GoogleIcon />}
          disabled={loading}
          fullWidth
        >
          Continue with Google
        </Button>

        {!(
          params.get("redirect") && params.get("redirect").startsWith("orders/")
        ) && (
          <Button
            onClick={loginAsGuest}
            variant="outlined"
            size="large"
            color="secondary"
            disabled={loading}
            fullWidth
          >
            Continue as Guest
          </Button>
        )}
      </div>
    </div>
  );
};

export default LoginScreen;
