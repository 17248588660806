import React, { lazy, Suspense, useEffect, useState } from "react";
import { Container, LinearProgress } from "@mui/material";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import CartScreen from "./screens/CartScreen/CartScreen";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import { getAuth } from "firebase/auth";
import { login } from "./redux/authSlice";
import Overlay from "./components/Overlay/Overlay";
import Error404 from "./components/Error404/Error404";
import Footer from "./components/footer/Footer";

const OrderItemScreen = lazy(() =>
  import("./screens/OrderItemScreen/OrderItemScreen")
);
const OrdersScreen = lazy(() => import("./screens/OrdersScreen/OrdersScreen"));
const AdminProduct = lazy(() => import("./screens/AdminScreen/AdminProduct"));
const CheckoutScreen = lazy(() =>
  import("./screens/CheckoutScreen/CheckoutScreen")
);
const CouponScreen = lazy(() => import("./screens/AdminScreen/CouponScreen"));
const AddEditCoupon = lazy(() => import("./screens/AdminScreen/AddEditCoupon"));

const App = () => {
  const auth = getAuth();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { user, authenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      dispatch(login());
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return <LinearProgress />;
  }
  return (
    <div className="mainBodyContainer">
      <HashRouter>
        <Navbar />
        <Container maxWidth="xl" style={{ paddingTop: "2rem" }}>
          <Suspense fallback={<Overlay open={true} />}>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/products/:id" element={<ProductScreen />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/orders/:id" element={<OrderItemScreen />} />

              <Route
                path="/checkout/*"
                element={
                  authenticated ? <CheckoutScreen /> : <Navigate to="/" />
                }
              />
              <Route
                path="/orders"
                element={
                  user.email !== "guest@user.com" ? (
                    <OrdersScreen />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/admin/coupons"
                element={user.admin ? <CouponScreen /> : <Navigate to="/" />}
              />

              <Route
                path="/admin/coupons/new"
                element={user.admin ? <AddEditCoupon /> : <Navigate to="/" />}
              />
              <Route
                path="/admin/coupons/edit/:id"
                element={user.admin ? <AddEditCoupon /> : <Navigate to="/" />}
              />

              <Route
                path="/admin/products"
                element={user.admin ? <AdminProduct /> : <Navigate to="/" />}
              />
              <Route
                path="/admin/products/:id"
                element={user.admin ? <AdminProduct /> : <Navigate to="/" />}
              />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
        </Container>
        <Toaster
          position="bottom-center"
          toastOptions={{ style: { background: "#333", color: "#FFF" } }}
        />
        <Footer />
      </HashRouter>
    </div>
  );
};

export default App;
